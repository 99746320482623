import { inject } from '@angular/core';

import { Observable } from 'rxjs';
import { BooktechAppService } from './booktech-app.service';

import { MiscUtil } from '../util/misc.util';

import { DynformControl, DynformControlsMap } from '../model/dymform-control';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

export class FormService {

  autoTips: Record<string, Record<string, string>> = {
    'nb': {
      required: 'Feltet er påkrevd',
      email: 'Feltet er ikke en epost-adresse',
    },
    'en': {
      required: 'Input is required',
      email: 'The input is not valid email',
    },
    default: { }
  }; //TODO: actrans?


  public debug = false;

  constructor(public bas:BooktechAppService) {
    // Log.log('FormService');
    this.autoTips['default'] = this.autoTips['nb'];
  }



  findSeparator(control: DynformControl, val?:string) {
    if (control.data.separator) return control.data.separator;
  
    if (val === undefined) val = control.value + "";
    if (val.indexOf(",") > 0) return ",";
    return ";";
  }
  toFormControlValue(control: DynformControl, val?:any) {
    if (val === undefined) val = control.value;
    let fcv = val;
    let ct = control.controlType;

    // console.log("toFormControlValue: " + val + ", type: " + (typeof val));
    
    switch(ct) {


      case "textarea": 

        if (typeof val === "string" && val.length > 0 && control.data.autoHeight !== false) {
  
          if (control.data.json) {

            fcv = this.bas.us.pretty(val);
          }
          // let val =  this.control.value;

         
          control.data.height = fcv ? Math.min(Math.max(fcv.split(/\r\n|\r|\n/).length * 20, 80), 20 * 40) : control.data.height;

          // if (this.bas.envtest) console.log("toFormControlValue.json, control: ", control);

        }

        // else if (typeof val === "string" && val.length > 0 && control.data.autoHeight !== false) {
  
        //   // fcv = this.bas.us.pretty(val);
        //   // let val =  this.control.value;

         
        //   control.data.height = val ? Math.min(Math.max((val.split(/\r\n|\r|\n/).length+1) * 20, 80), 20 * 40) : control.data.height;

        //   if (this.bas.envtest) console.log("toFormControlValue.autoHeight, count: "+val.split(/\r\n|\r|\n/).length+", control: ", control);

        // }
      
        break;
      case "time-picker":
       
        if (typeof val === "string") {
          if (val === "") fcv = null;
          else fcv = this.bas.ui.dateParse(val, control.data.format || "HH:mm");
          // console.log("fcv: " + val + " -> " + fcv);
        }
        break;

      case "datetime-picker":
       
        if (typeof val === "string") {
          if (val === "") fcv = null;
          else fcv = this.bas.ui.dateParse(val, control.data.format || "yyyy-MM-dd HH:mm");
          // console.log("fcv: " + val + " -> " + fcv);
        }
        break;
        
      case "date-picker":
        
        if (typeof val === "string") {
          if (val === "") fcv = null;
          else fcv = this.bas.ui.dateParse(val, control.data.format || "yyyy-MM-dd");
          // console.log("fcv: " + val + " -> " + fcv);
        }
        break;

      case "select":
        if (typeof val === "string") {
          if (control.selectMode == "tags") {
            fcv = val && val != "" ? val.split(",") : [];
          }
        }

        if (control.selectMode == "multiple" && typeof val === "string") {
          if (val == "") fcv = [];
          else {
            
            fcv = val.split(this.findSeparator(control, val));
          }
        }

        if (val === undefined && control.optionsAllowEmpty) fcv = "";

        if (this.debug) console.log("select, key: "+control.key+", val: ", val, " -> ", fcv);
  
        
        break;

      case "checkbox-list":
        
        if (typeof val === "string") {
          fcv = val.split(this.findSeparator(control, val))
          
        }
        if (this.debug) console.log("checkbox-list, key: "+control.key+", val: ", val, " -> ", fcv);
     
        break;

      case "tags":
        
        if (typeof val === "string") {
          fcv = { };
          let selected = val && val != "" ? val.split(this.findSeparator(control, val)) : [];
          for (let key of selected) fcv[key] = true;
          
        }
        if (this.debug) console.log("tags, key: "+control.key+", val: ", val, " -> ", fcv);
     
        break;


      case "mbsc-datepicker":
          
        if (typeof val === "string" && val.length) {
          fcv =  this.bas.ui.dateParse(val, control.data.dateFormat);
        }
        if (this.debug) console.log("mbsc-datepicker, val: ", val, " -> ", fcv);

        
        break;

      case "date-range-picker":
      
        
        if (typeof val === "string") {
          let parts = val != "" ? val.split("_") : [];
          let start = parts.length > 0 ? parts[0] : undefined;
          let end = parts.length > 1 ? parts[1] : undefined;
          
          if (this.debug && start)  console.log("start: " + start + " -> " + this.bas.ui.dateParse(start));
          // if (this.debug)  console.log("start: " + start + " -> " + this.bas.ui.dateParse(start));
          fcv = [ ];
          if (start || end) fcv.push(start ? this.bas.ui.dateParse(start)  : undefined);
          if (end) fcv.push(end ? this.bas.ui.dateParse(end)  : undefined);

        }
        if (this.debug) console.log("date-range-picker, val: ", val, " -> ", fcv);


        break;

        case "datetime-range-picker":
      
        
          if (typeof val === "string") {
            let parts = val != "" ? val.split("_") : [];
            let start = parts.length > 0 ? parts[0] : undefined;
            let end = parts.length > 1 ? parts[1] : undefined;
            
            // console.log("start: " + start + " -> " + this.bas.ui.dateParse(start));
            fcv = [ ];
            if (start || end) fcv.push(start ? this.bas.ui.dateParse(start, 'yyyy-MM-dd HH:mm')  : undefined);
            if (end) fcv.push(end ? this.bas.ui.dateParse(end, 'yyyy-MM-dd HH:mm')  : undefined);
  
          }
          if (this.debug) console.log("datetime-range-picker, val: ", val, " -> ", fcv);
  
  
          break;
        
        case "number-range":

          if (typeof val === "string") {
            let parts = val != "" ? val.split("_") : [];
            let start = parts.length > 0 ? parts[0] : undefined;
            let end = parts.length > 1 ? parts[1] : undefined;
            
            if (this.debug) console.log("start: " + start + " -> " + (start ? parseInt(start) : "undefined"));
            if (this.debug) console.log("end: " + end + " -> " + (end ? parseInt(end) : "undefined"));
            fcv = [ ];
            if (start || end) fcv.push(start ? parseInt(start)  : undefined);
            if (end) fcv.push(end ? parseInt(end)  : undefined);
  
          } else if (val === undefined) fcv = [];
          
          if (this.debug) console.log("number-range, key: "+control.key+", val: ", val, " -> ", fcv);
  
  
          break;

        case "slider-range":

          let arr:number[] = [];
          if (typeof val === "string") {
            let parts = val != "" ? val.split("_") : [];
            let start = parts.length > 0 ? parts[0] : undefined;
            let end = parts.length > 1 ? parts[1] : undefined;
            
            if (this.debug) console.log("start: " + start + " -> " + (start ? parseInt(start) : "undefined"));
            if (this.debug) console.log("end: " + end + " -> " + (end ? parseInt(end) : "undefined"));
            // fcv = [ ];
            if (start || end) arr.push(start ? parseInt(start) : control.data.min || 1);
            if (end) arr.push(parseInt(end));
            fcv = arr;
          } 
          // else if (val === undefined) fcv = [];
          
          
          if (this.debug) console.log("slider-range, key: "+control.key+", val: ", val, " -> ", fcv);
  
  
          break;
  
          
      case "tree-select":

        if (control.data.multiple && typeof val === "string") {
          fcv = val.split(this.findSeparator(control, val))
        }
        if (this.debug) console.log("tree-select.multiple, key: "+control.key+", val: ", val, " -> ", fcv);
  
        break;
 


        // 
    }

    
    return fcv;
  }
  fromFormControlValue(control: DynformControl, fcv?:any) {
    // if (fcv === undefined) fcv = this.value;
    let val = fcv;
    let ct = control.controlType;
    switch(ct) {
      case "label": 
        val = undefined; //TODO, iblant vil vi både vise å sende denne
        break;
        
      case "input": 
        if (control.type === "number" && typeof fcv === "string" && fcv.length != 0) {
          val = parseFloat(fcv);

          
        }
        if (this.debug) console.log("input.number, key: "+control.key+", type: "+(typeof fcv) +", fcv: ", fcv, " -> ", val);
        
      
        break;

      case "time-picker":
        // console.log("fromFormControlValue: " + fcv + ", type: " + (typeof fcv));
        if (fcv instanceof Date) {
          val = this.bas.ui.dateFormat(fcv, control.data.format || "HH:mm");
        } else {
          val = "";
        }
        break;

      case "datetime-picker":
          // console.log("fromFormControlValue: " + fcv + ", type: " + (typeof fcv));
          if (fcv instanceof Date) {
            val = this.bas.ui.dateFormat(fcv, control.data.format || "yyyy-MM-dd HH:mm");
          } else {
            val = "";
          }
          break;

        
      case "date-picker":
        // console.log("fromFormControlValue: " + fcv + ", type: " + (typeof fcv));
        if (fcv instanceof Date) {
          val = this.bas.ui.dateFormat(fcv, control.data.format || "yyyy-MM-dd");
        } else {
          val = "";
        }
        break;
  
        case "mbsc-datepicker":
          if (this.debug)  console.log("fromFormControlValue: " + fcv + ", type: " + (typeof fcv) + ", isNAN: " + isNaN(fcv));
          if (!isNaN(fcv) && fcv instanceof Date) {
            
            val = this.bas.ui.dateFormat(fcv, control.data.dateFormat || "yyyy-MM-dd");
          } else {
            val = "";
          }
          break;
  
          
      case "select":
      
        if (control.selectMode == "tags") {
          
          val = fcv && fcv.length ? fcv.join(",") : "";
          
        }
        if (control.selectMode == "multiple" && fcv && fcv.length !== undefined) {
          val = fcv.join(this.findSeparator(control))
        }
        if (this.debug) console.log("select.multiple, key: "+control.key+", fcv: ", fcv, " -> ", val);
        

        
        
        break;

      case "checkbox-list":
      
        if (fcv && fcv.length !== undefined) {
          val = fcv.join(this.findSeparator(control))
        }
        if (this.debug) console.log("checkbox-list, key: "+control.key+", fcv: ", fcv, " -> ", val);
        

        
        
        break;

      case "tags":
        val = "";
        let selected:any[] = []; 
        if (fcv && typeof fcv == "object") {
          for (let key of Object.keys(fcv)) {
            if (fcv[key]) selected.push(key);
          }
        }
        val = selected.join(this.findSeparator(control));

        if (this.debug) console.log("tags, key: "+control.key+", fcv: ", fcv, " -> ", val);
          
        break;

      // case "mbsc-datepicker":
        
      //   // console.log("range-picker, fcv: ", fcv)
      //   if (fcv) {

      //     val = (this.bas.ui.dateFormat(fcv as Date) || "")

      //     ;
      //   }
      //   if (this.debug) console.log("mbsc-datepicker, fcv: ", fcv, " -> ", val);
          
      //   break;

      case "date-range-picker":
        
        // console.log("range-picker, fcv: ", fcv)
        if (fcv && fcv.length !== undefined) {
          let start = fcv[0];
          let end = fcv.length > 1 ? fcv[1] : undefined;
          
          if (this.debug) console.log("date-range-picker, fcv: ", fcv, ", start: ", start, ", end: ", end);
        
          val = (this.bas.ui.dateFormat(start as Date) || "")
            + "_"
            + (end ? this.bas.ui.dateFormat(end as Date) : "")
          ;
        }
        if (this.debug) console.log("date-range-picker, fcv: ", fcv, " -> ", val);
          
        break;

        
      case "datetime-range-picker":
        
        if (this.debug) console.log("datetime-range-picker, key: "+control.key+", fcv: ", fcv)
        if (fcv && fcv.length !== undefined) {
          let start = fcv[0];
          let end = fcv.length > 1 ? fcv[1] : undefined;
          
          val = (start ? this.bas.ui.dateFormat(start as Date, 'yyyy-MM-dd HH:mm') : "" )
            + "_"
            + (end ? this.bas.ui.dateFormat(end as Date, 'yyyy-MM-dd HH:mm') : "")
          ;
        }
        if (this.debug) console.log("datetime-range-picker, fcv: ", fcv, " -> ", val);
          
        break;
      
      case "number-range":
      case "slider-range":
        
        if (this.debug) console.log("number/slider-range, key: "+control.key+", fcv: ", fcv)
        if (fcv && fcv.length !== undefined) {
          let start = fcv[0];
          let end = fcv.length > 1 ? fcv[1] : undefined;
          
          val = (start !== undefined ? start  : "" )
            + "_"
            + (end !== undefined  ? end : "")
          ;
        }
        if (this.debug) console.log("number/slider-range, key: "+control.key+", fcv: ", fcv, " -> ", val);
          
        break;


      case "tree-select":

        if (control.data.multiple && fcv && fcv.length !== undefined) {
          val = fcv.join(";")
        }
        if (this.debug) console.log("tree-select.multiple, key: "+control.key+", fcv: ", fcv, " -> ", val);
        
        break;
  

    }


    return val;
  }


  updateControlValues(controls: DynformControl[], source:any) {
    controls.forEach(control => {
      if (source.hasOwnNestedProperty(control.key)) {
        control.value = source[control.key];
      }
    });
  }

  toMap(controls: DynformControl[]):DynformControlsMap {
    let map: DynformControlsMap = { };

    controls.forEach(control => {
      map[control.key] = control
    });

    return map;
  }

  toOptionList(arr:any) {
    return (arr || []).map((val:any) => { return { value: val, label: val }});
  }


  toFormControl(control: DynformControl, options:any = { } ):UntypedFormControl|UntypedFormGroup {
    let ct = control.controlType;
    let isCheckbox = ct == "checkbox" ;
    let fc:UntypedFormControl|UntypedFormGroup|null = null;

    if (ct === "formGroup") {

      fc = control.fg = this.toFormGroup(control.children);

    } else if (ct == "message") {

      const mg = this.toFormGroup(control.children);

      fc = control.fg = mg;
      
    } else {
      let stateValue = this.toFormControlValue(control);

      // if (ct == "time-picker") {
      //   if (this.bas.envtest) console.log("stateValue: " + stateValue + ", type: " + (typeof stateValue));
      //   if (typeof stateValue == "string") {
      //     stateValue = this.bas.ui.dateParse(stateValue, control.data.format || "HH:mm");
      //     if (this.bas.envtest) console.log("");
      //   }
      // }
      // if (stateValue == undefined) stateValue = "";

      let fcState = { 
        value : stateValue, 
        disabled: control.disabled 
      };
      fc = new UntypedFormControl( fcState );
      // if (isCheckbox) fc.setValue(false)

      // if (ct == "number-range") console.log("toFormGroup, key: " + control.key + ", fcState: ", fcState);


      let vals:ValidatorFn[] = control.validators || [];
      if (control.required) {
        vals.push(isCheckbox ? Validators.requiredTrue : Validators.required);
        
      }


      let ct = control.controlType;

      
      if (ct == "input" && control.type == "email") {
        // if (this.bas.envtest) console.log("using emailValidator for control: ", control)
        // vals.push(Validators.email);
        vals.push(BtValidators.emailValidator);
        
        // vals.push(Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));
      }

      if (ct == "input" || ct == "input-number") {
        if (control.data.min != undefined) vals.push(BtValidators.minLength(control.data.min));
        if (control.data.max != undefined) vals.push(BtValidators.maxLength(control.data.max));
      }
      if (((ct == "input" && control.type == "number") || ct == "input-number")) {
        if (control.data.min != undefined) vals.push(BtValidators.min(control.data.min));
        if (control.data.max != undefined) vals.push(BtValidators.max(control.data.max));
      }

      if ((ct == "textarea" || ct == "textarea-count") && control.data.max != undefined) {
        vals.push(BtValidators.maxLength(control.data.max));
      }

      
   
      fc.setValidators(vals);
    }

    control.fc = fc;
    return fc;
    
  }


  toFormGroup(input: DynformControl[] | DynformControlsMap, options:any = { } ):UntypedFormGroup {
    if (options == undefined) options = { }
    const group: any = {};
    
    let controls = input instanceof DynformControlsMap ? Object.values(input) : input;
    let sorted = controls.sort((a, b) => a.order - b.order)

    sorted.forEach(control => {

      let fc = this.toFormControl(control);
      

      group[control.key] = fc;
    });


    let fg = new UntypedFormGroup(group);
 

    sorted.forEach(control => {
      if (control.fg === undefined )  control.fg = fg;
    });


    return fg;
  }

  updateControls(controls: DynformControl[], source:any, logMissing = false) {
    controls.forEach(control => {
      control.source = source;
      let valuePath = control.valuePath; // || control.key;
      let value:string|undefined = undefined;
      
      // if (this.bas.envtest) logMissing = true;

      if (source) {
        if (MiscUtil.hasOwnNestedProperty(source, valuePath)) {
          value = MiscUtil.getOwnNestedProperty(source, valuePath);
        } else {
          if (logMissing ) {
            if (this.bas.envtest) console.log("WARN, source is missing property, control: "+control.key+":  ", control, ", source: ", source); 
          }
        }
      } else {
        if (this.bas.envtest) console.log("WARN, source is undefined, setting value to empty string, key: " + control.key);
        // value = "";
        if (control.value === undefined) {
          value = "";
        } 
      }

        let valType = typeof value;
        let isBool = valType === "boolean";

        // if (isBool) console.log("settiing control value, key: " + control.key + ", v: " + control.value + " -> " + value);
        // if (this.bas.envtest && control.controlType === "weekdays") console.log("key: " + control.key + ", type: " + (typeof value) + ", value: ", value); 
 
        if (value !== undefined) {
          
          control.value = value;
        }
      
        if (control.controlType == "") {
          control.controlType = isBool ? "checkbox" : "input";
        }

       

        if (control.controlType === "weekdays") {

          

        } else if (control.controlType === "formGroup") {
          // if (this.bas.envtest) console.log("controlType == formGroup, value: ("+(value==undefined) + ")",  value, ", control: ", control);
          this.updateControls(control.children, value || source, logMissing);

        } else if (control.controlType === "message") {

          control.data.message = value;

          let langs:any[] = control.data.langs || this.bas.ds.findCompany()?.enabledLanguages || this.bas.ds.config.appConfig?.enabledLanguages || [];
          let message = control.data.message?.texts || { };
          let ct = control.data.controlType || "input";

          // if (this.bas.envtest) console.log("controlType == message, value: ", value, ", langs: ",  langs);
  
          let children:DynformControl[] = [];
  
          langs.forEach((lang) => {
            let lc = lang.code;
            let m = message[lc];
  
            let cc = MiscUtil.clone(control);
            cc.show = control.show; // || ((control:DynformControl) =>  { return true });
            cc.onChange = control.onChange;
            cc.controlType = ct;
            cc.value = m || "";
            cc.key = lc; // control.key + "_" + lc;
            cc.mk = ""; //lang.mk;
            cc.label = ""; //lang.mkName;
            cc.sorce = message;
            cc.data.langName =  lang.mkName;
  
            children.push(cc);

            // console.log("lang: ", lang);
  
            // let fcm = new FormControl(m || "");
  
            // mg[lc] = fcm;
  
          });

          control.children = children;
        
          
        }

        if (control.children) {
          control.childMap = this.toMap(control.children);
        }


     
    });
  }

  validateFormControls(controls:{
    [key: string]: AbstractControl;
  }): boolean {
    let allValid = true;
    for (const key in controls) {
        let control = controls[key];
        if (control instanceof UntypedFormGroup) this.validateForm(control);
        

        let isPrestine = control.pristine;
        // console.log(i + ": pristine: " + control.pristine + "; valid: " + control.valid);
        control.markAsDirty();
        // console.log(i + ": pristine: " + control.pristine + "; valid: " + control.valid);
        
        control.updateValueAndValidity();
        // console.log(i + ": pristine: " + control.pristine + "; valid: " + control.valid + ", value: ", control.value);
        
        if (control.disabled) continue;

        if (isPrestine &&  control.valid) {
          // TODO: disse kan "Hoppes" over
        }

        if (!control.valid) {
          allValid = false;
          if (this.bas.envtest) console.log("!control.valid, key: "+key+", isPrestine: "+isPrestine+", control: ", control); 
        }
    }
    return allValid;
  }

  validateForm(form:UntypedFormGroup): boolean {
    // let controls = [];
    // for (const i in form.controls) {
    //   if (form.controls.hasOwnProperty(i)) {
    //     let control = form.controls[i];
    //     controls.push(control);

    //   }
    // }
  
    this.validateFormControls(form.controls);
    return form.valid;
  }

  rv(ctx:any, ignoreInvalid = false, validate = true) {
    if (!ctx) return false;
    if (!ctx.form || !ctx.controls) return false;

    return this.getRawValue(ctx.form, ctx.controls, ignoreInvalid, validate);
  }

  getRawValue(form:UntypedFormGroup, controls:DynformControlsMap, ignoreInvalid = false, validate = true):any {
    if (validate) this.validateForm(form);

    // console.log("P: ", this.p);
    if (form.invalid) {
      if (this.bas.envtest) console.log("form.invalid, form: ", form);
      if (!ignoreInvalid) return false;
    }
    // console.log("AdminProductPageComponent.submitForm, loginForm.values: ", form.getRawValue());


    let rv = form.getRawValue();
    if (this.bas.envtest) console.log("getRawValue rv: ", rv);
    this.updateRawValues(rv, controls);

    if (this.debug) console.log("getRawValue, VALID: "+ !form.invalid+", validate: " + validate +", form: ", rv);
    
    return rv;

  }
  updateRawValues(rawValue:any, controls:DynformControlsMap) {
    Object.values(controls).forEach((control) => {

      
      let fcv = MiscUtil.getOwnNestedProperty(rawValue, control.valuePath);
      let updateKeyValue = false;
      if (fcv === undefined && control.valuePath.indexOf(".") > -1) {
        fcv = MiscUtil.getOwnNestedProperty(rawValue, control.key);
        updateKeyValue = fcv !== undefined;
      }
      let val = this.fromFormControlValue(control, fcv);

      if (this.debug) console.log("rv.key: " + control.key + ", vp: " + control.valuePath +", fcv: " + fcv  + " ("+(typeof fcv)+")" + " -> " + val + " ("+(typeof val)+"), updateKeyValue: " + updateKeyValue);

      if (control.controlType === "formGroup") {
        this.updateRawValues(val, control.childMap);
      }

      if (fcv !== val) {
        // console.log("fcv: " + fcv + " -> " + val + " ("+(typeof val)+")");
        MiscUtil.setOwnNestedProperty(rawValue, updateKeyValue ? control.key : control.valuePath, val);
      }
      
    });
  }


  updateFormObj(formObj:any, controls: DynformControl[], obj:any, logMissing:boolean = false) {
    this.updateControls(controls, obj, logMissing);

    formObj.form = this.toFormGroup(controls);
    formObj.controls = this.toMap(controls);
    formObj.obj = obj;
  }

  // appendFormObj(parent:UntypedFormGroup, ) { // se  /cbamin/bergensportsreiser/order -> generateControlsOrderItem
  //   let controls = this.edit.form.controls.itemsMap.controls;

  //   this.bas.fs.updateControls(dcItem.children, item, true);
  //   let fg = this.bas.fs.toFormGroup(dcItem.children, { });
  //   controls[item.id] = fg
  //   dcItems.childMap[item.id] = dcItem;
  //   dcItem.childMap = this.bas.fs.toMap(dcItem.children);
  //   dcItem.fg = fg;
  // }

 
}




export type BtErrorsOptions = { nb: string; en: string } & Record<string, NzSafeAny>;
export type BtValidationErrors = Record<string, BtErrorsOptions>;

export class BtValidators extends Validators {
  static override min(min: number): ValidatorFn {
    return (control: AbstractControl): BtValidationErrors | null => {
      if (Validators.min(min)(control) === null) {
        return null;
      }
      return { 
        minlength: { 
          nb: `Minimumsverdi ${min}`, 
          en: `Minimum value is ${min}` 
        } 
      };
    };
  }

  static override minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): BtValidationErrors | null => {
      if (Validators.minLength(minLength)(control) === null) {
        return null;
      }
      return { 
        minlength: { 
          nb: `Minimumslengde ${minLength}`, 
          en: `Minimum length is ${minLength}` 
        } 
      };
    };
  }

  static override maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): BtValidationErrors | null => {
      if (Validators.maxLength(maxLength)(control) === null) {
        return null;
      }
      return { 
        maxlength: { 
          nb: `Maksimumslengden er ${maxLength}`, 
          en: `Max length is ${maxLength}` 
        } 
      };
    };
  }
  static emailValidator(control: AbstractControl): BtValidationErrors | null {
    // return (control): BtValidationErrors | null => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      // console.log("btEmail, valid: "+emailPattern.test(control.value||"")+", c: ", control);
      if (control.value == undefined || emailPattern.test(control.value)) { // 
        return null;
      }
      // if (Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)(control) === null) {
      //   return null;
      // }
      return { 
        invalidEmail: { 
          nb: `Ugyldig e-postadresse`, 
          en: `Invalid e-mail address` 
        } 
      };
    // };
  }

  // static atLeastOne(): https://stackoverflow.com/questions/41020069/require-one-from-two-fields-using-angular-2

  // static mobile(control: AbstractControl): BtValidationErrors | null {
  //   const value = control.value;
  //   if (isEmptyInputValue(value)) {
  //     return null;
  //   }
  //   return isMobile(value)
  //     ? null
  //     : { mobile: { 'zh-cn': `手机号码格式不正确`, en: `Mobile phone number is not valid` } };
  // }
}

function isEmptyInputValue(value: NzSafeAny): boolean {
  return value == null || value.length === 0;
}

function isMobile(value: string): boolean {
  return typeof value === 'string' && /(^1\d{10}$)/.test(value);
}
